$(document).foundation();

var sticky = document.getElementById("site-header");
var value = sticky.offsetTop;

window.onscroll = function () {
    if(window.pageYOffset > 0){
        sticky.classList.add("sticky");
        // document.querySelector('body').classList.add("sticky-nav");

    } else{
        sticky.classList.remove("sticky");
        // document.querySelector('body').classList.remove("sticky-nav");
    }
};
